import React from 'react'
import moment from 'moment'
import './index.scss'

const days = ["Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam.", "Dim."]

const CalendrierIcon = ({ openCalendrier, steps }) => {
    return (
        <article onClick={openCalendrier} className={`calendrierIconContainer ${steps.inscriptionShowcase ? '' : 'notif'}`}>
            <div className="calendrierIcon">
                <p className="day">{days[moment().day() - 1]}</p>
                <p className="chiffre">{moment().date()}</p>
            </div>
            <p className="title">Calendrier</p>
        </article>
    )
}

export default CalendrierIcon