import React from 'react'
import FolderImg from '../../assets/img/folder.png'
import './index.scss'

const FolderIcon = ({ icon, setCurrentFolder }) => {
    const openFolder = () => {
        setCurrentFolder(icon)
    }

    return (
        <article className="folderIcon">
            <img onClick={openFolder} src={FolderImg} alt="Ouvrir le dossier" />
            <p>{icon?.name}</p>
        </article>
    )
}

export default FolderIcon